import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import {
    Row, Col, Card, CardBody, Button, Input, Label, Form
} from 'reactstrap';
import Select from 'react-select'


const Purchase = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [companyOpt, setCompanyOpt] = useState('');
    const [transportOpt, setTransportOpt] = useState('');
    const [productOpt, setProductOpt] = useState('');
    const [unitOpt, setUnitOpt] = useState('');
    const [custOpt, setCustOpt] = useState('');

    const [company, setCompany] = useState('0');
    const [challanNo, setChallanNo] = useState('');
    const [product, setProduct] = useState('0');
    const [bagQty, setBagQty] = useState(1);
    const [weight, setWeight] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [unit, setUnit] = useState('0');
    const [rate, setRate] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cashDiscount, setCashDiscount] = useState(0);
    const [claimAmount, setClaimAmount] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [purchaseFrom, setPurchaseFrom] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [gst, setGst] = useState('');
    //
    const [weightTo, setWeightTo] = useState('');
    const [weightToRate, setWeightToRate] = useState('');
    const [rejectionWeight, setRejectionWeight] = useState('');
    const [rejectionRate, setRejectionRate] = useState('');
    
    const [invoiceNo, setInvoiceNo] = useState('');
    const [invGstAmt, setInvGstAmt] = useState(0);
    const [invoiceValue, setInvoiceValue] = useState(0);
    
    const [vehicleNo, setVehicleNo] = useState('');
    const [transportCharge, setTransportCharge] = useState('');
    const [gstAmt, setGstAmt] = useState('');
    const [totTransAmt, setTotTransAmt] = useState('');
    const [transportName, setTransportName] = useState('');
    const [advancePayment, setAdvancePayment] = useState('');
    const [completePayment, setCompletePayment] = useState('');

    const [brokerName, setBrokerName] = useState('0');
    const [brokerOpt, setBrokerOpt] = useState('');



    useEffect(() => {
        get_init();
    }, []);

    const get_init = () => {

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return <option key={index} value={row.company_code} >{row.name}</option>
                });
                setCompanyOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/broker_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return {value: row.broker_id, label: row.broker_name } 
                });
                setBrokerOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_2 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.product_master_id, label: row.product_name} 

                });
                setProductOpt(list_2);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/unit_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_3 = response.data.map((row, index) => {
                    return <option key={index} value={row.uom_id} >{row.uom_desc + ' (' + row.uom_code + ')'}</option>
                });
                setUnitOpt(list_3);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
         //  
         axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_4 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.customer_id, label: row.name, gst: row.gst_no} 
                });
                setCustOpt(list_4);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_5 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.transport_master_id, label: row.transport_master_name } 
                });
                setTransportOpt(list_5);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const purchase_entry_handler = (e) => {
        e.preventDefault();
        let valid = true;

        if(company === "0"){
            valid = false;
            let notify = notification({ message: "Please select company", type: 'warn' });
            notify();
        }
        if(challanNo == ""){
            valid = false;
            let notify = notification({ message: "Please enter Daliy Challan Number", type: 'warn' });
            notify();
        }
        if(product === "0"){
            valid = false;
            let notify = notification({ message: "Please select product", type: 'warn' });
            notify();
        }
        if(unit === "0"){
            valid = false;
            let notify = notification({ message: "Please select unit", type: 'warn' });
            notify();
        }
        if(weight === 0){
            valid = false;
            let notify = notification({ message: "Please enter weight", type: 'warn' });
            notify();
        }
        if(rate === 0){
            valid = false;
            let notify = notification({ message: "Please enter rate", type: 'warn' });
            notify();
        }
        
        let postData = {
            "product_master_id": product,
            "trns_type": 1,
            "company_code": company,
            "challan_no": challanNo,
            "bags": bagQty,
            "weight": weight,
            "unit": unit,
            "rate": rate,
            "gst": 0,
            "total_price": totalPrice,
            "cash_discount": cashDiscount,
            "claim_amount": claimAmount,
            "customer_id": purchaseFrom.value,
            "customer_name": purchaseFrom.label,
            "purchase_date": purchaseDate,

            "invoice_no": invoiceNo,
            "invoice_gst_amount": invGstAmt,
            "invoice_value": invoiceValue,

            "vahicle_no": vehicleNo,
            "transport_chrg": transportCharge,
            "gst_amt": gstAmt,
            "transport_amt": totTransAmt,
            "trns_name": transportName,
            "advance_payment": advancePayment,
            "complete_payment": completePayment,
            
            "broker_id": brokerName,
            //
            "weight2": weightTo,
            "weight2_rate": weightToRate,
            "weight2_amount": weightTo * weightToRate,
            "rejection_weight": rejectionWeight,
            "rejection_rate": rejectionRate,
            "reject_amount": rejectionWeight * rejectionRate,
        }

        if(valid){
            axios({
                method: 'post',
                url: mainUrl + 'ProductMaster/product_trns_add',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    console.log(response.data);
                    navigate('/transaction-list')
                    let notify = notification({ message: "Submit successfully", type: 'success' });
                    notify();
                    
    
                }).catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                })
        }


    }

    const total_price_calculate = (bag, wgt, rate) => {

        let tot_weight = Number(wgt) * Number(bag);
        let tot_amt = tot_weight * rate;
        setTotalWeight(tot_weight);
        setTotalPrice(Number(tot_amt).toFixed(2));
        calculate_amount_handler(tot_amt, 3);

    }

    const calculate_amount_handler = (val, type) => {
        let total = 0; 
        if(type === 1){
            setCashDiscount(val);
            total = Number(totalPrice) - Number(val) - Number(claimAmount) ;
        }else if(type === 2){
            setClaimAmount(val); 
            total = Number(totalPrice) - Number(cashDiscount) - Number(val);
        }else if(type === 3){
            total = Number(val) - Number(cashDiscount) - Number(claimAmount);
        }

        setTotalAmt(Number(total).toFixed(2));

    }


    return (

        <Page>
            <Row>
                <Col xs={12}>

                    <h5 className='text-primary'>Purchase Entry Page</h5>
                    <Card>
                        <CardBody>
                            <Form onSubmit={purchase_entry_handler}>
                                <Row>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="purchase_from"> Purchase From (Customer Name)*</Label>
                                        <Select options={custOpt} onChange={opt => {setPurchaseFrom(opt); setGst(opt.gst)}} placeholder="Select Customer" required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="gst"> GST Number </Label>
                                        <Input id="gst" placeholder="GST Number" type="text" value={gst} readOnly />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="gst"> Daily Challan No. </Label>
                                        <Input id="gst" placeholder="Daily Challan No." type="text" value={challanNo} onChange={(e) => setChallanNo(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="purchase_date"> Purchase Date* </Label>
                                        <Input id="purchase_date" name="purchase_date" placeholder="Purchase Date" type="date" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="Company"> Company* </Label>

                                        <Input id="Company" name="Company" placeholder="Company" type="select" value={company} onChange={(e) => setCompany(e.target.value)} required >
                                            <option value="0"> --Select Company-- </option>
                                            {companyOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="product"> Product* </Label>
                                        <Select options={productOpt} onChange={opt => setProduct(opt.value)} placeholder="Select Product" required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="qty"> Bags Quantity* </Label>
                                        <Input id="qty" name="qty" placeholder="Bags Quantity" type="number" min="1" value={bagQty} onChange={(e) => { setBagQty(e.target.value); total_price_calculate(e.target.value, weight, rate); }} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="weight"> Weight / Bag* </Label>
                                        <Input id="weight" name="weight" placeholder="Weight" type="number" min="0.00" step="0.001" value={weight} onChange={(e) => { setWeight(e.target.value); total_price_calculate(bagQty, e.target.value, rate); }} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="unit"> Unit* </Label>
                                        <Input id="unit" name="unit" placeholder="Unit" type="select" value={unit} onChange={(e) => setUnit(e.target.value)} required >
                                            <option value="0"> --Select Unit-- </option>
                                            {unitOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="total_weight"> Total Weight </Label>
                                        <Input id="total_weight" name="total_weight" placeholder="Total Weight" type="number" value={totalWeight} onChange={(e) => setTotalWeight(bagQty * weight)} readOnly />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="rate"> Rate / Unit* </Label>
                                        <Input id="rate" name="rate" placeholder="Rate" min="0.00" step="0.001" type="Number" onChange={(e) => { setRate(e.target.value); total_price_calculate(bagQty, weight, e.target.value); }} value={rate} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="total_price"> Amount </Label>
                                        <Input id="total_price" name="total_price" placeholder="Amount" type="Number" value={totalPrice} readOnly />
                                    </Col>

                                    <Col xs={4} className='mb-2'>
                                        <Label for="weightTo"> Weight (Extra)</Label>
                                        <Input id="weightTo" name="weightTo" placeholder="Weight (Extra)" type="number" value={weightTo} onChange={(e) => setWeightTo(e.target.value)}  />
                                    </Col>
                                    
                                    <Col xs={4} className='mb-2'>
                                        <Label for="weightTo"> Rate (Extra) </Label>
                                        <Input id="weightTo" name="weightTo" placeholder="Rate (Extra)" type="number" value={weightToRate} onChange={(e) => setWeightToRate(e.target.value)}  />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="rejectionWeight"> Rejection Weight </Label>
                                        <Input id="rejectionWeight" name="rejectionWeight" placeholder="Rejection Weight" type="number" value={rejectionWeight} onChange={(e) => setRejectionWeight(e.target.value)}  />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="rejectionRate"> Rejection Rate </Label>
                                        <Input id="rejectionRate" name="rejectionRate" placeholder="Rejection Rate" type="number" value={rejectionRate} onChange={(e) => setRejectionRate(e.target.value)}  />
                                    </Col>
                                    
                                    <Col xs={4} className='mb-2'>
                                        <Label> Cash Discount </Label>
                                        <Input id="amt" name="amt" placeholder="Cash Discount" type="number" min="0.00" step="0.001" value={cashDiscount} onChange={(e) => calculate_amount_handler(e.target.value, 1)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Claim Amount </Label>
                                        <Input id="amt" name="amt" placeholder="Claim Amount" type="number" min="0.00" step="0.001" value={claimAmount} onChange={(e) => calculate_amount_handler(e.target.value, 2)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Total Amount </Label>
                                        <Input id="amt" name="amt" placeholder="Total Amount" type="number" value={totalAmt} readOnly />
                                    </Col>

                                    <Col xs={12} className='mb-2 mt-3 '><h6>Add Invoice Details</h6></Col>
                                    
                                    <Col xs={4} className='mb-2'>
                                        <Label for="Invoice Number"> Invoice Number </Label>
                                        <Input placeholder="Invoice Number" type="text" value={invoiceNo} onChange={(e)=>setInvoiceNo(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="Invoice GST Amount"> Invoice GST Amount </Label>
                                        <Input placeholder="Invoice GST Amount" type="Number" value={invGstAmt}  onChange={(e)=>setInvGstAmt(e.target.value)}  />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="Invoice Value"> Invoice Value </Label>
                                        <Input placeholder="Invoice Value" type="Number" value={invoiceValue} onChange={(e)=>setInvoiceValue(e.target.value)} />
                                    </Col>

                                    <Col xs={12} className='mb-2 mt-3 '><h6>Add Broker Details</h6></Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="brokerName"> Broker Name </Label>
                                        <Select options={brokerOpt} onChange={opt => setBrokerName(opt.value)} placeholder="Select Broker" />
                                    </Col>

                                    <Col xs={12} className='mb-2 mt-3 '><h6>Add Transport Details</h6></Col>

                                    <Col xs={4} className='mb-2'>
                                        <Label for="TransportName"> Transport Name </Label>
                                        <Select options={transportOpt} onChange={opt => setTransportName(opt.value)} placeholder="Select Transport" />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="vehicleNo"> Vehicle Number </Label>
                                        <Input id="vehicleNo" placeholder="Vehicle Number" type="text" value={vehicleNo} onChange={(e)=>setVehicleNo(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="transportCharge"> Transport charge </Label>
                                        <Input id="transportCharge" name="transportCharge" placeholder="Transport Charge" type="Number" value={transportCharge} onChange={(e) => setTransportCharge(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="rate"> GST Amount </Label>
                                        <Input id="rate" name="rate" placeholder="GST Amount" type="Number" value={gstAmt} onChange={(e) => setGstAmt(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="total_price"> Transport Amount </Label>
                                        <Input id="total_price" name="total_price" placeholder="Total Transport Amount" type="Number" value={totTransAmt} onChange={(e) => setTotTransAmt(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="AdvancePayment"> Advance Payment </Label>
                                        <Input id="AdvancePayment" name="AdvancePayment" placeholder="Advance Payment" type="Number" value={advancePayment} onChange={(e) => setAdvancePayment(e.target.value)} />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="CompletePayment"> Complete Payment </Label>
                                        <Input id="CompletePayment" name="CompletePayment" placeholder="Complete Payment" type="Number" value={completePayment} onChange={(e) => setCompletePayment(e.target.value)} />
                                    </Col>

                                    <Col xs={12} className='mb-2'>
                                        <b>Note: </b> (*) Mark field are mandatory.
                                    </Col>
                                    
                                    <Col xs={12} className='mb-2 text-end'>
                                        <Button className='text-white mt-4' block color='primary'> Submit </Button>
                                    </Col>
                                </Row>
                            </Form>


                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default Purchase;