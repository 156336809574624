import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import {
    Row, Col, Card, CardBody, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill  } from "react-icons/bs";
import { MdDeleteForever  } from "react-icons/md";

const TransactionList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [filterData, setFilterData] = useState('');
    

    useEffect(() => {
        get_init();
    }, []);

    const get_init = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_trns_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let total_amt = Number(row.total_price) + Number(row.weight2_amount) - Number(row.reject_amount);
                    return{
                        sl: index + 1,
                        tran_id: row.product_trns_id,
                        trns_type: row.trns_type == 1 ? "Purchase" : "Sale",
                        company: row.name,
                        product_name: row.product_name,
                        bags: row.bags,
                        weight: row.weight +' '+ row.uom_desc,
                        tot_weight: row.weight * row.bags  +' '+ row.uom_desc,
                        rate: row.rate,
                        total_price: total_amt,
                        customer_name: row.customer_name,
                        purchase_date: row.purchase_date.split(" ")[0].split('-').reverse().join('-'),
                        broker: row.broker_name,
                        view: <div>
                            <BsFillArrowRightSquareFill className='cursor me-3' size={25} color="#0188c7" onClick={()=>view_handler(row.product_trns_id)} />
                            <MdDeleteForever className='cursor' color='#fb3a3b' size={25} onClick={() => delete_handler(row)} />
                        </div>,
                    }
                })
                setTableData(list);
                setFilterData(list);
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    const view_handler = (id) => {
        navigate("/transaction-view", {state: {trans_id: id}})
    }

    const filter_data_handler = (e) => {
        let fData = tableData.filter (item => {
            const query = e.target.value.toLowerCase();
            console.log(query)
            return (
                item.trns_type.toLowerCase().indexOf(query) >= 0 ||
                item.tran_id.toLowerCase().indexOf(query) >= 0 ||
                item.customer_name.toLowerCase().indexOf(query) >= 0 || 
                item.purchase_date.toLowerCase().indexOf(query) >= 0 
            )
        })
        setFilterData(fData);

    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete this Payment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "trns_id": row.product_trns_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/trns_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Transcation deleted successfully", type: 'success' });
                                notify();
                                get_init();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });

    }


    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Transaction List</h5>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search by Type, Date, Customer Name, ' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: "80px"
                                    },
                                    {
                                        name: 'Transaction ID',
                                        selector: row => row.tran_id,
                                        width: "130px"
                                    },
                                    {
                                        name: 'Type',
                                        selector: row => row.trns_type,
                                        width: "100px"
                                    },
                                    {
                                        name: 'Broker Name',
                                        selector: row => row.broker,
                                        wrap: true
                                    },
                                    {
                                        name: 'Customer Name',
                                        selector: row => row.customer_name,
                                        wrap: true
                                    },
                                    {
                                        name: 'Date',
                                        selector: row => row.purchase_date,
                                        wrap: true
                                    },
                                    {
                                        name: 'Product Name',
                                        selector: row => row.product_name,
                                        wrap: true
                                    },
                                   
                                    {
                                        name: 'Total Weight',
                                        selector: row => row.tot_weight,
                                        wrap: true
                                    },
                                    {
                                        name: 'Total Value',
                                        selector: row => row.total_price,
                                        wrap: true
                                    },
                                    {
                                        name: 'View',
                                        selector: row => row.view,
                                        wrap: true
                                    },
                                    
                                ]}
                                data={filterData}
                                striped
                                highlightOnHover
                                pagination
                                progressPending={loading}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default TransactionList;