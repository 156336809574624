import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import Select from 'react-select'


import {
    Row, Col, Card, CardBody, Button, Input, Label, Form, CardFooter, Spinner, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


const TransactionView = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);

    const [payType, setPayType] = useState('0');
    const [paymentAmt, setPaymentAmt] = useState(0);

    const [transData, setTransData] = useState('');
    const [paymentData, setPaymentData] = useState('');

    
    //
    const [editModal, setEditModal] = useState(false);


    const [companyOpt, setCompanyOpt] = useState([]);
    const [transportOpt, setTransportOpt] = useState([]);
    const [productOpt, setProductOpt] = useState([]);
    const [unitOpt, setUnitOpt] = useState([]);
    const [custOpt, setCustOpt] = useState([]);
    const [brokerOpt, setBrokerOpt] = useState([]);

    const [broker, setBroker] = useState('0');
    const [company, setCompany] = useState('0');
    const [challanNo, setChallanNo] = useState('');
    const [product, setProduct] = useState('0');
    const [bagQty, setBagQty] = useState(1);
    const [weight, setWeight] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [unit, setUnit] = useState('0');
    const [rate, setRate] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cashDiscount, setCashDiscount] = useState(0);
    const [claimAmount, setClaimAmount] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [purchaseFrom, setPurchaseFrom] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [gst, setGst] = useState('');
    
    const [invoiceNo, setInvoiceNo] = useState('');
    const [invGstAmt, setInvGstAmt] = useState(0);
    const [invoiceValue, setInvoiceValue] = useState(0);
    
    const [vehicleNo, setVehicleNo] = useState('');
    const [transportCharge, setTransportCharge] = useState('');
    const [gstAmt, setGstAmt] = useState('');
    const [totTransAmt, setTotTransAmt] = useState('');
    const [transportName, setTransportName] = useState('');
    const [advancePayment, setAdvancePayment] = useState('');
    const [completePayment, setCompletePayment] = useState('');
    //
    const [weightTo, setWeightTo] = useState('');
    const [weightToRate, setWeightToRate] = useState('');
    const [rejectionWeight, setRejectionWeight] = useState('');
    const [rejectionRate, setRejectionRate] = useState('');

    useEffect(() => {
        get_init();
        get_all_option();
    }, []);

    const get_all_option = () => {

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return <option key={index} value={row.company_code} >{row.name}</option>
                });
                setCompanyOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/broker_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return {value: row.broker_id, label: row.broker_name } 
                });
                setBrokerOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_2 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.product_master_id, label: row.product_name} 

                });
                setProductOpt(list_2);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/unit_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_3 = response.data.map((row, index) => {
                    return <option key={index} value={row.uom_id} >{row.uom_desc + ' (' + row.uom_code + ')'}</option>
                });
                setUnitOpt(list_3);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
         //  
         axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_4 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.customer_id, label: row.name, gst: row.gst_no} 
                });
                setCustOpt(list_4);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_5 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.transport_master_id, label: row.transport_master_name } 
                });
                setTransportOpt(list_5);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const get_init = () => {

        let postData = { "product_trns_id": location.state.trans_id }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_trns_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let result = response.data[0];
                setTransData(result);
                //edit 
                setChallanNo(result.challan_no);
                setPurchaseDate(result.purchase_date.split(" ")[0]);
                setCompany(result.company_code);
                setBroker(result.broker_id);
                setProduct(result.product_master_id);
                setBagQty(result.bags);
                setWeight(result.weight);
                setUnit(result.unit);
                setRate(result.rate);
                setCashDiscount(result.cash_discount);
                setClaimAmount(result.claim_amount);
                total_price_calculate(result.bags, result.weight, result.rate);

                setInvoiceNo(result.invoice_no);
                setInvGstAmt(result.invoice_gst_amount);
                setInvoiceValue(result.invoice_value);

                setTransportName(result.trns_name);
                setVehicleNo(result.vahicle_no);
                setTransportCharge(result.transport_chrg);
                setGstAmt(result.gst_amt);
                setTotTransAmt(result.transport_amt);
                setAdvancePayment(result.advance_payment);
                setCompletePayment(result.complete_payment);
                //
                setWeightTo(result.weight2);
                setWeightToRate(result.weight2_rate);
                setRejectionWeight(result.rejection_weight);
                setRejectionRate(result.rejection_rate);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        let postData1 = { "trans_id": location.state.trans_id }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_payment_detail',
            data: postData1,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                let list = response.data.map((row, index) => {
                    let type = '';
                    if (row.payment_type == 1) {
                        type = 'Cash';
                    } else if (row.payment_type == 2) {
                        type = 'Cheque';
                    } else if (row.payment_type == 3) {
                        type = 'Online';
                    }
                    return {
                        "sl": index + 1,
                        "payment_date": row.payment_date.split(" ")[0].split('-').reverse().join('-'),
                        "payment_amt": row.payment_amt,
                        "payment_type": type,
                        "customer_name": row.name,
                    }
                });

                setPaymentData(list);

            }).catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "Failed! Try Again", type: 'error' });
                //notify();
            })
        //
    }

    const payment_add_handler = (e) => {
        e.preventDefault();

        let valid = true;

        if (payType == 0) {
            valid = false;
            let notify = notification({ message: "Please select payment type", type: 'warn' });
            notify();
        }
        if (paymentAmt == 0) {
            valid = false;
            let notify = notification({ message: "Please enter payment amount", type: 'warn' });
            notify();
        }

        let postData = {
            "trans_id": location.state.trans_id,
            "customer_id": transData.customer_id,
            "payment_type": payType,
            "payment_amt": paymentAmt
        }

        if (valid) {

            axios({
                method: 'post',
                url: mainUrl + 'ProductMaster/payment_add',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {

                    let notify = notification({ message: "Added successfully", type: 'success' });
                    notify();
                    setPaymentModal(false);
                    get_init();

                }).catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                })
        } else {

        }
    }


    const total_price_calculate = (bag, wgt, rate) => {

        let tot_weight = Number(wgt) * Number(bag);
        let tot_amt = tot_weight * rate;
        setTotalWeight(tot_weight);
        setTotalPrice(Number(tot_amt));
        calculate_amount_handler(tot_amt, 3);

    }

    const calculate_amount_handler = (val, type) => {
        let total = 0; 
        if(type === 1){
            setCashDiscount(val);
            total = Number(totalPrice) - Number(val) - Number(claimAmount) ;
        }else if(type === 2){
            setClaimAmount(val); 
            total = Number(totalPrice) - Number(cashDiscount) - Number(val);
        }else if(type === 3){
            total = Number(val) - Number(cashDiscount) - Number(claimAmount);
        }

        setTotalAmt(Number(total));
    }

    const edit_handler = () => {
        setLoading(true);
        let postData = {
            "product_master_id": product,
            "trns_type": transData.trns_type,
            "company_code": company,
            "bags": bagQty,
            "weight": weight,
            "rate": rate,
            "unit": unit,
            "gst": 0,
            "total_price": totalPrice,
            "cash_discount": cashDiscount,
            "claim_amount": claimAmount,
            "customer_id": transData.customer_id,
            "customer_name": transData.customer_name,
            "purchase_date": purchaseDate,
            "vahicle_no": vehicleNo,
            "transport_chrg": transportCharge,
            "transport_amt": totTransAmt,
            "gst_amt": gstAmt,
            "invoice_no": invoiceNo,
            "invoice_gst_amount": invGstAmt,
            "invoice_value": invoiceValue,
            "trns_name": transportName,
            "advance_payment": advancePayment,
            "complete_payment": completePayment,
            "challan_no": challanNo,
            "trns_id": transData.trns_id,
            "broker_id": broker,
            //
            "weight2": weightTo,
            "weight2_rate": weightToRate,
            "weight2_amount": weightTo * weightToRate,
            "rejection_weight": rejectionWeight,
            "rejection_rate": rejectionRate,
            "reject_amount": rejectionWeight * rejectionRate,
        }

        console.log(postData);

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_trns_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setEditModal(false);
                get_init();
                let notify = notification({ message: "Update successfully", type: 'success' });
                notify();
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>

                    <h5 className='text-primary'>Transaction View</h5>
                    <Card>
                        <CardBody>
                            <Row>
                                <h6 className='text-primary text-center mb-3 '><b>Transaction Details </b></h6>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Transaction ID</b></Label>
                                    <div>{transData?.product_trns_id}</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Transaction Type</b></Label>
                                    <div>{transData?.trns_type == 1 ? 'Purchase' : 'Sale'}</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Customer Name</b></Label>
                                    <div>{transData?.customer_name}</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Company Name</b></Label>
                                    <div>{transData?.name}</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Product Name</b></Label>
                                    <div>{transData?.product_name}</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Bags</b></Label>
                                    <div>{transData?.bags} No(s)</div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Weight / Bag</b></Label>
                                    <div>{transData?.weight} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Total Weight</b></Label>
                                    <div>{Number(transData?.bags) * Number(transData?.weight)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Rate / Unit </b></Label>
                                    <div>Rs. {Number(transData?.rate)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Amount </b></Label>
                                    <div>Rs. {Number(transData?.total_price)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Cash Discount </b></Label>
                                    <div>Rs. {Number(transData?.cash_discount)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Claim Amount </b></Label>
                                    <div>Rs. {Number(transData?.claim_amount)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Total Amount </b></Label>
                                    <div>Rs. {Number(Number(transData?.total_price) - (Number(transData?.cash_discount) + Number(transData?.claim_amount)))} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Invoice Number </b></Label>
                                    <div>{transData?.invoice_no} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Invoice GST Amount </b></Label>
                                    <div>Rs. {Number(transData?.invoice_gst_amount)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Invoice Value </b></Label>
                                    <div>Rs. {Number(transData?.invoice_value)} </div>
                                </Col>

                                <hr />
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Weight (Extra) </b></Label>
                                    <div>{Number(transData?.weight2)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Rate (Extra) </b></Label>
                                    <div>{Number(transData?.weight2_rate)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b> Total Amount (Extra) </b></Label>
                                    <div>Rs. {Number(transData?.weight2_amount)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Rejection Weight </b></Label>
                                    <div> Rs. {Number(transData?.rejection_weight)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Rejection Rate </b></Label>
                                    <div>Rs. {Number(transData?.rejection_rate)} </div>
                                </Col>
                                <Col xs={3} className='mb-3'>
                                    <Label><b>Total Rejection Amount </b></Label>
                                    <div>Rs. {Number(transData?.reject_amount)} </div>
                                </Col>
                                <hr />
                                <Col xs={12}>
                                    <h6 className='text-primary text-center mb-3 '><b>Transport Details </b></h6>
                                    <Row>
                                        <Col xs={3} className='mb-3'>
                                            <Label><b>Vehicle Number </b></Label>
                                            <div> {transData?.vahicle_no} </div>
                                        </Col>
                                        <Col xs={3} className='mb-3'>
                                            <Label><b>Transport Charge / Unit </b></Label>
                                            <div>Rs. {Number(transData?.transport_chrg)} </div>
                                        </Col>
                                        <Col xs={3} className='mb-3'>
                                            <Label><b>GST Amount </b></Label>
                                            <div>Rs. {Number(transData?.gst_amt)} </div>
                                        </Col>
                                        <Col xs={3} className='mb-3'>
                                            <Label><b>Total Transport Amount </b></Label>
                                            <div>Rs. {Number(transData?.transport_amt)} </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <hr />


                                <Col xs={{ offset: 2, size: 8 }} className='mb-3 '>
                                    <h6 className='text-primary text-center mb-3 '><b>Payment Details </b></h6>

                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "80px"
                                            },
                                            {
                                                name: 'Payment Date',
                                                selector: row => row.payment_date,
                                            },
                                            {
                                                name: 'Payment Amount',
                                                selector: row => row.payment_amt,
                                            },
                                            {
                                                name: 'Payment Type',
                                                selector: row => row.payment_type,
                                            },
                                            {
                                                name: 'Customer Name',
                                                selector: row => row.customer_name,
                                                wrap: true
                                            },

                                        ]}
                                        data={paymentData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}

                                    />
                                </Col>
                            </Row>

                        </CardBody>
                        <CardFooter className='text-end'>

                            {/* transData?.conf_status == 0 &&
                                <Button className='text-white me-5' onClick={confirm_received_handler} color='success'> Confirm Receiving </Button>
                            <Button className='text-white me-5' color='primary' onClick={() => {setInvoiceModal(true); confirm_received_handler()}}> Genrate Invoice </Button>
                             <Button className='text-white me-5' color='primary' onClick={create_json}> Genrate Invoice </Button>
                             */}


                            <Button className='text-white' color='primary' onClick={() => setEditModal(true)}> Edit </Button>
                            <Button className='text-white ms-4' color='primary' onClick={() => setPaymentModal(true)}> Add Payment </Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={paymentModal}
                toggle={() => setPaymentModal(!paymentModal)}
            >
                <ModalHeader toggle={() => setPaymentModal(!paymentModal)}>
                    Add Payment
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={payment_add_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="customer_name"> Customer Name </Label>
                                <Input id="customer_name" placeholder="Customer Name" type="text" value={transData?.customer_name} readOnly />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="customer_name"> Payment Type </Label>
                                <Input id="customer_name" placeholder="Customer Name" type="select" value={payType} onChange={(e) => setPayType(e.target.value)} required >
                                    <option value="0">-- Select Payment Type --</option>
                                    <option value="1">Cash</option>
                                    <option value="2">Cheque</option>
                                    <option value="3">Online</option>
                                </Input>
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="paymentAmt"> Payment Amount </Label>
                                <Input id="paymentAmt" placeholder="paymentAmt" type="text" value={paymentAmt} onChange={(e) => setPaymentAmt(e.target.value)} required />
                            </Col>
                            {/*<Col xs={12} className='mb-2'>
                                <Label for="paymentDate"> Payment Date </Label>
                                <Input id="paymentDate" placeholder="payment Date" type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} required />
                            </Col>*/}
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal
                centered
                scrollable

                size="xl"
                isOpen={editModal}
                toggle={() => setEditModal(!editModal)}
            >
                <ModalHeader toggle={() => setEditModal(!editModal)}>
                    Edit
                </ModalHeader>
                <ModalBody>
                    <Row>
                        
                        <Col xs={4} className='mb-2'>
                            <Label for="gst"> Daily Challan No. </Label>
                            <Input id="gst" placeholder="Daily Challan No." type="text" value={challanNo} onChange={(e) => setChallanNo(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="purchase_date"> Purchase Date* </Label>
                            <Input id="purchase_date" name="purchase_date" placeholder="Purchase Date" type="date" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} required />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="Company"> Company* </Label>

                            <Input id="Company" name="Company" placeholder="Company" type="select" value={company} onChange={(e) => setCompany(e.target.value)} required >
                                <option value="0"> --Select Company-- </option>
                                {companyOpt}
                            </Input>
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="product"> Product* </Label>
                            <Select options={productOpt} 
                            value={productOpt.filter(opt => opt.value == product)}
                            onChange={opt => setProduct(opt.value)} placeholder="Select Product" required />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="qty"> Bags Quantity* </Label>
                            <Input id="qty" name="qty" placeholder="Bags Quantity" type="number" min="1" value={bagQty} onChange={(e) => { setBagQty(e.target.value); total_price_calculate(e.target.value, weight, rate); }} required />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="weight"> Weight / Bag* </Label>
                            <Input id="weight" name="weight" placeholder="Weight" type="number" value={weight} onChange={(e) => { setWeight(e.target.value); total_price_calculate(bagQty, e.target.value, rate); }} required />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="unit"> Unit* </Label>
                            <Input id="unit" name="unit" placeholder="Unit" type="select" value={unit} onChange={(e) => setUnit(e.target.value)} required >
                                <option value="0"> --Select Unit-- </option>
                                {unitOpt}
                            </Input>
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="total_weight"> Total Weight </Label>
                            <Input id="total_weight" name="total_weight" placeholder="Total Weight" type="text" value={totalWeight} onChange={(e) => setTotalWeight(bagQty * weight)} readOnly />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="rate"> Rate / Unit* </Label>
                            <Input id="rate" name="rate" placeholder="Rate" type="Number" onChange={(e) => { setRate(e.target.value); total_price_calculate(bagQty, weight, e.target.value); }} value={rate} required />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="total_price"> Amount </Label>
                            <Input id="total_price" name="total_price" placeholder="Amount" type="Number" value={totalPrice} readOnly />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label> Cash Discount </Label>
                            <Input id="amt" name="amt" placeholder="Cash Discount" type="number" min="0" value={cashDiscount} onChange={(e) => calculate_amount_handler(e.target.value, 1)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label> Claim Amount </Label>
                            <Input id="amt" name="amt" placeholder="Claim Amount" type="number" min="0" value={claimAmount} onChange={(e) => calculate_amount_handler(e.target.value, 2)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label> Total Amount </Label>
                            <Input id="amt" name="amt" placeholder="Total Amount" type="number" min="0" value={totalAmt} readOnly />
                        </Col>

                        <hr/>

                        <Col xs={4} className='mb-2'>
                            <Label for="weightTo"> Weight (Extra)</Label>
                            <Input id="weightTo" name="weightTo" placeholder="Weight (Extra)" type="number" value={weightTo} onChange={(e) => setWeightTo(e.target.value)}  />
                        </Col>
                        
                        <Col xs={4} className='mb-2'>
                            <Label for="weightTo"> Rate (Extra) </Label>
                            <Input id="weightTo" name="weightTo" placeholder="Rate (Extra)" type="number" value={weightToRate} onChange={(e) => setWeightToRate(e.target.value)}  />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="rejectionWeight"> Rejection Weight </Label>
                            <Input id="rejectionWeight" name="rejectionWeight" placeholder="Rejection Weight" type="number" value={rejectionWeight} onChange={(e) => setRejectionWeight(e.target.value)}  />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="rejectionRate"> Rejection Rate </Label>
                            <Input id="rejectionRate" name="rejectionRate" placeholder="Rejection Rate" type="number" value={rejectionRate} onChange={(e) => setRejectionRate(e.target.value)}  />
                        </Col>

                        <Col xs={12} className='mb-2 mt-3 '><h6>Add Invoice Details</h6></Col>
                        

                        <Col xs={4} className='mb-2'>
                            <Label for="Invoice Number"> Invoice Number </Label>
                            <Input placeholder="Invoice Number" type="text" value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="Invoice GST Amount"> Invoice GST Amount </Label>
                            <Input placeholder="Invoice GST Amount" type="Number" value={invGstAmt} onChange={(e) => setInvGstAmt(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="Invoice Value"> Invoice Value </Label>
                            <Input placeholder="Invoice Value" type="Number" value={invoiceValue} onChange={(e) => setInvoiceValue(e.target.value)} />
                        </Col>
                        <hr/>


                        <Col xs={12} className='mb-2 mt-3 '><h6>Add Broker Details</h6></Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="Broker"> Broker Name </Label>
                            <Select options={brokerOpt} 
                            value={brokerOpt.filter(opt => opt.value == broker)}
                            onChange={opt => setBroker(opt.value)} placeholder="Select Broker" />
                        </Col>


                        <Col xs={12} className='mb-2 mt-3 '><h6>Add Transport Details</h6></Col>

                        <Col xs={4} className='mb-2'>
                            <Label for="TransportName"> Transport Name </Label>
                            <Select options={transportOpt} 
                            value={transportOpt.filter(opt => opt.value == transportName)}
                            onChange={opt => setTransportName(opt.value)} placeholder="Select Transport" />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="vehicleNo"> Vehicle Number </Label>
                            <Input id="vehicleNo" placeholder="Vehicle Number" type="text" value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="transportCharge"> Transport charge </Label>
                            <Input id="transportCharge" name="transportCharge" placeholder="Transport Charge" type="Number" value={transportCharge} onChange={(e) => setTransportCharge(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="rate"> GST Amount </Label>
                            <Input id="rate" name="rate" placeholder="GST Amount" type="Number" value={gstAmt} onChange={(e) => setGstAmt(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="total_price"> Transport Amount </Label>
                            <Input id="total_price" name="total_price" placeholder="Total Transport Amount" type="Number" value={totTransAmt} onChange={(e) => setTotTransAmt(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="AdvancePayment"> Advance Payment </Label>
                            <Input id="AdvancePayment" name="AdvancePayment" placeholder="Advance Payment" type="Number" value={advancePayment} onChange={(e) => setAdvancePayment(e.target.value)} />
                        </Col>
                        <Col xs={4} className='mb-2'>
                            <Label for="CompletePayment"> Complete Payment </Label>
                            <Input id="CompletePayment" name="CompletePayment" placeholder="Complete Payment" type="Number" value={completePayment} onChange={(e) => setCompletePayment(e.target.value)} />
                        </Col>

                        <Col xs={12} className='mb-2 text-end'>
                            <Button className='text-white mt-4' color='primary' onClick={edit_handler} disabled={loading} > UPDATE {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                        </Col>
                    </Row>


                </ModalBody>

            </Modal>

        </Page >
    )
}

export default TransactionView;